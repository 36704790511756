
<template>
    <div class="container">
        <div class="right_cont">
            <div class="title flex_center_between_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic1.png" />
                    Contact Info
                </div>
                <div class="title_txt-right">
                    <div class="tbl_search_box">
                        <!-- <div class="tbl_search">
              <el-input v-model="searchTblName" type="text" clearable placeholder="Please enter name" @change="searchPersonByName()"/>
              <img src="../../assets/img/detail/search_ic1.png" @click="searchPersonByName"/>
            </div> -->
                        <div class="tbl_search">
                            <el-input v-model="keyWord" type="text" clearable placeholder="Please enter email" @change="searchPersonByWord()" />
                            <img src="../../assets/img/detail/search_ic1.png" @click="searchPersonByWord()" />
                        </div>
                    </div>
                    <download-excel class="export-excel-wrapper computer_right_cont_box" :data="DetailsForm" :fields="json_fields" :header="title" name="Contact.xls">
                        <div class="btn_export flex_center" @click="exportCompany">
                            <img src="../../assets/img/search/export_ic1.png" />
                            Export
                        </div>
                    </download-excel>
                </div>
            </div>
            <div class="right_cont_box" v-loading="loading">
                <div class="table_box p_l_n">
                    <div class="tbl_search_box tbl_search2" v-show="isNameSearch">
                        <div class="tbl_search">
                            <el-input v-model="searchTblName" type="text" clearable placeholder="Please enter name" @change="searchPersonByName()" @blur="seach" />
                            <img src="../../assets/img/detail/search_ic1.png" @click="searchPersonByName" />
                        </div>
                    </div>
                    <el-table ref="treeTable" class="computer_right_cont_box" :data="managementTable" border style="width: 100%">
                        <template slot="empty">
                            <noDate />
                        </template>
                        <el-table-column label="Name of a person" prop="name" align="left" width="210" sortable :sort-method="sortName" show-overflow-tooltip :resizable="false" class-name="company_link">
                            <template slot="header">
                                <span class="table_add" @click="showSearchPerson()">
                                    Name of a person
                                    <img src="../../assets/img/detail/search_ic1.png" />
                                </span>
                            </template>
                            <template slot-scope="scope">
                                <span v-show="!showTranslate" class="tbl_company">{{ scope.row.name }}</span>
                                <span v-show="showTranslate" class="tbl_company">{{ scope.row.nameCn }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Position" prop="position" width="310" align="left" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Department" prop="department" width="170" align="left" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Email" prop="email" width="210" align="left" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Phone" prop="phone" width="160" align="left" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Verified" width="99" prop="verified" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                            <template slot-scope="scope">
                                <button style="background-color: white"><img v-if="scope.row.verified == '1'" src="../../assets/img/detail/tbl_img1.png" class="verified_img" /></button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="phone_page_bg">
                        <div class="table_box">
                            <ul class="phone_table_box">
                                <li class="phone_table_box_item" v-for="(item, index) in managementTable" :key="index">
                                    <div class="phone_table_box_item_right">
                                        <h1 class="tbl_company line_1" @click="setid(item)">{{ item.name }}</h1>
                                        <h2 class="line_1">Position: {{ item.position }}</h2>
                                        <h2 class="line_1">
                                            <span>Department:{{ item.department }}</span>
                                        </h2>
                                        <h2 class="line_1">
                                            <span>Email:{{ item.email }}</span>
                                        </h2>
                                        <h2 class="line_1">
                                            <span>Phone:{{ item.phone }}</span>
                                        </h2>
                                        <h2 class="line_1">
                                            <span>
                                                Verified:
                                                <button style="background-color: white"><img v-if="item.verified == '1'" src="../../assets/img/detail/tbl_img1.png" class="verified_img" /></button>
                                            </span>
                                        </h2>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="addDialog" :append-to-body="true" center :close-on-click-modal="false" custom-class="dialog_normal2">
            <div class="right_cont">
                <div class="title flex_center_between_box">
                    <div></div>
                    <div class="title_txt-right">
                        <div class="tbl_search_box">
                            <div class="tbl_search">
                                <el-input v-model="keyWord" type="text" clearable placeholder="Please enter email" @change="searchPersonByWord()" />
                                <img src="../../assets/img/detail/search_ic1.png" @click="searchPersonByWord()" />
                            </div>
                        </div>
                        <download-excel class="export-excel-wrapper computer_right_cont_box" :data="DetailsForm" :fields="json_fields" :header="title" name="Contact.xls">
                            <div class="btn_export flex_center" @click="exportCompany">
                                <img src="../../assets/img/search/export_ic1.png" />
                                Export
                            </div>
                        </download-excel>
                    </div>
                </div>
                <div class="right_cont_box" v-loading="loading">
                    <div class="table_box p_l_n">
                        <div class="tbl_search_box tbl_search2" v-show="isNameSearch">
                            <div class="tbl_search">
                                <el-input v-model="searchTblName" type="text" clearable placeholder="Please enter name" @change="searchPersonByName()" @blur="seach" />
                                <img src="../../assets/img/detail/search_ic1.png" @click="searchPersonByName" />
                            </div>
                        </div>
                        <el-table ref="treeTable" class="computer_right_cont_box" :data="managementTable1" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <noDate />
                            </template>
                            <el-table-column label="Name of a person" prop="name" align="left" sortable :sort-method="sortName" width="210" show-overflow-tooltip :resizable="false" class-name="company_link">
                                <template slot="header">
                                    <span class="table_add" @click="showSearchPerson()">
                                        Name of a person
                                        <img src="../../assets/img/detail/search_ic1.png" />
                                    </span>
                                </template>
                                <template slot-scope="scope">
                                    <span v-show="!showTranslate" class="tbl_company">{{ scope.row.name }}</span>
                                    <span v-show="showTranslate" class="tbl_company">{{ scope.row.nameCn }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Position" prop="position" width="310" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Department" prop="department" width="170" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Email" prop="email" width="210" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Phone" prop="phone" width="160" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Verified" width="98" prop="verified" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                <template slot-scope="scope">
                                    <button style="background-color: white"><img v-if="scope.row.verified == '1'" src="../../assets/img/detail/tbl_img1.png" class="verified_img" /></button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="phone_page_bg">
                            <div class="table_box">
                                <ul class="phone_table_box">
                                    <li class="phone_table_box_item" v-for="(item, index) in managementTable1" :key="index">
                                        <div class="phone_table_box_item_right">
                                            <h1 class=" line_1">{{ item.name }}</h1>
                                            <h2 class="line_1">Position: {{ item.position }}</h2>
                                            <h2 class="line_1">
                                                <span>Department:{{ item.department }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>Email:{{ item.email }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>Phone:{{ item.phone }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>
                                                    Verified:
                                                    <button style="background-color: white"><img v-if="item.verified == '1'" src="../../assets/img/detail/tbl_img1.png" class="verified_img" /></button>
                                                </span>
                                            </h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page1.total" @onPageChange="onPageChange2" @currtentPageChange="currtentPageChange2"></Page>
                            <div class="search_rsult_txt line_2">{{ page1.pageNo }}-{{ page1.pageSize }} of over {{ page1.total | FilterNum }} results</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getContactInfo, updateContractInfo } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import noDate from '@/components/tableNoDate';
import Page from '@/components/page';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { noDate, Page },
    data() {
        return {
            loading: true,
            addDialog: false,
            load: false,
            searchTblName: '',
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page1: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            isNameSearch: false,
            // searchName: '',
            keyWord: '',
            managementTable: [],
            managementTable1: [],
            // 下载
            title: 'Contact Info',
            json_fields: {
                'Name of a person': 'name',
                Position: 'position',
                Department: 'department',
                Email: 'email',
                Phone: 'phone',
            },
            DetailsForm: [],
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    created() {
        this.getData();
    },
    methods: {
        sortName(a, b) {
            return a.name.localeCompare(b.name);
        },
        seach() {
            if (this.searchTblName == '') {
                this.isNameSearch = false;
            }
        },
        // 获取数据
        getData() {
            const id3a = this.$route.query.id3a;

            const companyCountry = this.$route.query.companyCountry;
            // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
            const base = JSON.parse(sessionStorage.getItem('base'));
            // const website=base.base.website
            const website = base.base.website;
            // const website=''
            // const keyWord=this.keyWord
            let params = 'aaaId=' + id3a + '&countryCode=' + companyCountry + '&page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&website=' + website + '&keyWord=' + this.searchTblName;
            this.loading = true;
            getContactInfo(params).then(result => {
                // let data = JSON.parse(crypto.decrypt(result))
                console.log('result- contactInfo', result);
                // this.loading=false
                if (result && result.code && result.data) {
                    this.loading = false;

                    this.managementTable = result.data.contacts;
                    this.page.total = result.data.total;
                } else {
                    this.loading = false;
                    this.managementTable = [];
                    this.page.pageNo = 1;
                    this.page.total = 0;
                    this.page.pageSize = 10;
                }
            });
        },
        setid(row) {
            let routeData = this.$router.resolve({
                path: '/search/searchPerson?searchname=' + row.name + '&companyCountry=' + '',
            });
            window.open(routeData.href, '_blank');
            // sessionStorage.setItem('person', JSON.stringify(row))
        },
        // name搜索筛选

        searchPersonByName() {
            this.page.pageNo = 1;
            if (this.addDialog) {
                this.searhinfo();
            } else {
                this.getData();
            }

            if (this.searchTblName == '') {
                this.isNameSearch = false;
            }
            // let list = this.managementTable
            // let keyWord = this.searchTblName
            // var arr = []
            // if (keyWord == '') {
            //   this.managementTable=list
            // } else {
            //   arr = list.filter((item) => {
            //     // console.log(`item`, item);
            //     return item.name.toUpperCase().indexOf(keyWord.toUpperCase())!==-1
            //   })
            //   console.log(`arr`, arr);
            //   this.managementTable=arr
            // }
            // this.onPageChange(1)
        },

        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getData();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getData();
        },
        onPageChange2(pageNo) {
            this.page1.pageNo = pageNo;
            this.searhinfo();
        },
        currtentPageChange2(pageSize) {
            this.page1.pageSize = pageSize;
            this.searhinfo();
        },
        //认证
        async hanldVerified(row) {
            this.load = true;
            row.verified = 1;
            let params = 'aaaId=' + this.$route.query.id3a + '&company_tag=' + row.companyTag + '&email=' + row.email + '&name_check=1';
            let res = await updateContractInfo(params);
            if (res && res.code) {
                row.verified = 1;
                this.load = false;
            } else {
                this.load = false;
            }
        },
        //table 搜索
        showSearchPerson() {
            this.isNameSearch = !this.isNameSearch;
        },
        //table 搜索
        searchPersonByWord() {
            this.addDialog = true;
            // this.page1.pageNo=1
            this.searhinfo();
        },
        // 获取 input 搜索信息
        searhinfo() {
            const id3a = this.$route.query.id3a;

            const companyCountry = this.$route.query.companyCountry;
            // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
            const base = JSON.parse(sessionStorage.getItem('base'));
            // const website=base.base.website
            const website = this.keyWord == '' ? base.base.website : this.keyWord;
            // const keyWord=this.keyWord
            let params = 'aaaId=' + id3a + '&countryCode=' + companyCountry + '&page=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize + '&website=' + website + '&keyWord=' + this.searchTblName;
            this.loading = true;
            getContactInfo(params).then(result => {
                // let data = JSON.parse(crypto.decrypt(result))
                // console.log('result- contactInfo', result)
                // this.loading=false
                if (result && result.code && result.data) {
                    this.loading = false;

                    this.managementTable1 = result.data.contacts;
                    this.page1.total = result.data.total;
                } else {
                    this.loading = false;
                    this.managementTable1 = [];

                    this.page1.pageNo = 1;
                    this.page1.total = 0;
                    this.page1.pageSize = 10;
                }
            });
        },
        Losefocus() {
            if (this.keyWord == '') {
                this.isNameSearch = false;
            }
            //
        },
        //导出
        exportCompany() {
            if (this.curPagePower[0].power) {
                if (this.addDialog) {
                    this.DetailsForm = this.managementTable1;
                } else {
                    this.DetailsForm = this.managementTable;
                }
            } else {
                this.message();
                // this.$parent.getOrdersAdd(1).then(res => {
                //     if (res) this.DetailsForm = this.managementTable
                // });
            }
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.right_cont {
    margin-top: 60px;
}
.title {
    margin-bottom: 10px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.dialog_normal2 {
    width: 1200px !important;
    background: #ffffff;
    border-radius: 20px;
    /* margin-top: 20vh !important; */
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.table_box {
    position: relative;
}
.table_box /deep/ .el-table th {
    height: 40px;
}
.table_add img {
    width: 14px;
    margin-left: 4px;
}
.tbl_search_box {
    display: flex;
    /* position: absolute;
  left: 0; */
    /* margin-right: 20px; */
}
.title_txt-right {
    display: flex;
}
.tbl_search {
    margin-right: 15px;
    position: relative;
    width: 290px;
    height: 40px;
    z-index: 9;
}
.tbl_search2 {
    margin-right: 15px;
    position: absolute;
    top: 5px !important;
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
.tbl_search /deep/ .el-input .el-input__suffix {
    right: 40px;
}
.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}
.verified_img {
    width: 24px;
    height: 20px;
}
@media (max-width: 821px) {
    .right_cont {
        margin-top: 20px !important;
    }
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 25px;
        height: 15px;
        margin-top: 5px;
        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .verified_img {
        width: 18px !important;
        height: 15px !important;
        margin-left: 5px;
    }
    .tbl_search {
        width: 200px !important;
    }
    .phone_table_box_item_right .line_1 {
    white-space: nowrap !important;
}
}
</style>
